<template>
  <div class="py-14" style="background-color:#fff;min-height:100vh">
    <v-list class="py-0">
      <v-list-item-group>

        <v-list-item to="/inventory/stock">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Stok</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>

        <v-list-item to="/inventory/stock-card">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Kartu Stok</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>

        <v-list-item to="/inventory/stock-adjustment">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Penyesuaian Stok</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>

        <!-- <v-list-item to="/inventory/stock-conversion"> -->
        <v-list-item @click="$store.dispatch('not_found')">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Konversi Stok</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>


        <!-- <v-list-item to="/inventory/stock-transfer"> -->
        <v-list-item @click="$store.dispatch('not_found')">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Transfer Stok</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>

        <!-- <v-list-item to="/inventory/stock-opname"> -->
        <v-list-item @click="$store.dispatch('not_found')">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Opname Stok</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>

      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  data: () => ({

  }),
  watch: {

  },
  computed: {

  },
  created() {

  },
  mounted(){
    
  },
  methods: {

  },
}
</script>